import React, { Fragment } from "react";
import { SImage, SHeader } from "../../StyledComponents";

const PackingPage = () => {
  return (
    <Fragment>
      <SImage src="Images/Packing/Packing2.jpg" alt="Packing" />
      <br></br>
      <br></br>
      <SHeader>What to pack / bring along</SHeader>
      <br></br>
      <ul>
        <li>your own breakfast for the duration of the weekend</li>
        <li>your own drinks for the entire weekend</li>
        <li>camping chairs / blankets / pillows for sitting on the lawn</li>
        <li>bicycles / hiking shoes</li>
        <li>sunblock and hat</li>
        <li>mozquito repellent</li>
        <li>Camera</li>
        <li>
          any Southey family info / photos that you think others might enjoy
          reading / looking at
        </li>
        <li>toys for your babies / kids</li>
        <li>if you play a musical instrument, bring it along</li>
        <li>pack clothes for hot and cold weather</li>
      </ul>
      <hr />
    </Fragment>
  );
};

export default PackingPage;
