import React, { Component, Fragment } from "react";
import { SHeader, SMiddle } from "../../StyledComponents";
import { Table } from "reactstrap";
import styled from "styled-components";
import { ACCOUNT_ORDER_ID } from "../../../constants/routes";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import authHeader from "../../../helpers/auth-header";

import { GET_USER_ORDERS } from "../../../constants/apiUrls";

const SHeaderRight = styled.th`
  text-align: right;
`;

const SCellRight = styled.td`
  text-align: right;
`;

const ValueCell = ({ value }) => {
  return <SCellRight>R {value.toFixed(2)}</SCellRight>;
};

class OrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      orders: []
    };
  }

  getOrders() {
    Axios.get(GET_USER_ORDERS, { headers: authHeader() })
      .then(response =>
        response.data.map(order => ({
          orderId: order.orderId,
          orderDate: `${order.orderDate}`,
          orderStatus: `${order.orderStatus}`,
          guestCount: order.guestCount,
          orderTotal: order.orderTotal
        }))
      )
      .then(orders => {
        this.setState({
          orders,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.getOrders();
  }

  render() {
    const { isLoading, orders, error } = this.state;

    return (
      <Fragment>
        <SHeader>Orders:</SHeader>

        <SMiddle>
          <p>
            Below you will find your past orders, please click on a row to see
            the details on that order.
          </p>
        </SMiddle>

        {error ? <pre>{error.message}</pre> : null}
        {isLoading ? <pre>I am Loading...</pre> : null}

        {!isLoading && !error && (
          <Table bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Status</th>
                <th>RSVP count</th>
                <SHeaderRight>Total</SHeaderRight>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr
                  onClick={() =>
                    this.props.history.push(ACCOUNT_ORDER_ID(order.orderId))
                  }
                  key={index}
                >
                  <th scope="row">{order.orderId}</th>
                  <td>{order.orderDate}</td>
                  <td>{order.orderStatus}</td>
                  <td>{order.guestCount}</td>
                  <ValueCell value={order.orderTotal} />
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Fragment>
    );
  }
}

export default withRouter(OrdersPage);
