import React, { Fragment, Component } from "react";
import { SHeader, SMiddle } from "../../StyledComponents";
import { Table, Container, Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import Axios from "axios";
import authHeader from "../../../helpers/auth-header";
import { GET_USER_ORDER } from "../../../constants/apiUrls";

import PayFastRedirectPage from "../../Orders/payFastRedirect";

const SHeaderRight = styled.th`
  text-align: right;
`;

const SHeader80 = styled.th`
  width: 80%;
`;

const SHeaderGoods = styled.th`
  width: 60%;
`;

const SCellRight = styled.td`
  text-align: right;
  white-space: pre;
`;

const SCellBoldRight = styled.td`
  text-align: right;
  font-weight: bold;
  white-space: pre;
`;

const SValueCell = ({ value }) => {
  return isNaN(value) ? (
    <SCellRight>R - </SCellRight>
  ) : (
    <SCellRight>R {value.toFixed(2)}</SCellRight>
  );
};
const TotalCell = ({ value }) => {
  return isNaN(value) ? (
    <SCellBoldRight>R - </SCellBoldRight>
  ) : (
    <SCellBoldRight>R {value.toFixed(2)}</SCellBoldRight>
  );
};

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: this.props.match.params.orderId,
      isLoading: true,
      error: null,
      order: {},
      isPaymentPage: false
    };
  }

  getOrder() {
    Axios.get(GET_USER_ORDER(this.state.orderId), { headers: authHeader() })
      .then(response => response.data)
      .then(order => {
        this.setState({
          order,
          isLoading: false
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    this.state.orderId
      ? this.getOrder()
      : this.setState({ error: "INVALID PATH (ORDER ID)" });
  }
  render() {
    const { error, order, orderId, isPaymentPage } = this.state;

    return (
      <Fragment>
        {!isPaymentPage ? (
          <Fragment>
            <SHeader>Order {orderId} Information:</SHeader>
            <SMiddle>
              <p>Below is are the specific details of your order:</p>
            </SMiddle>
            {!error ? (
              <Fragment>
                <hr />
                <Container>
                  <Row xs="1" sm="3">
                    <Col>
                      <strong>Order #:</strong> {orderId}
                    </Col>
                    <Col>
                      <strong>Ordered:</strong>
                      <br />
                      {order.orderDate}
                    </Col>
                    <Col>
                      <strong>Status:</strong> {order.orderStatus}
                      <br />
                      {order.orderStatus !== "AwaitingPayment" && (
                        <Fragment>
                          <strong>PayDate:</strong> {order.paymentDate}
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </Container>
                <hr />
                {order.orderStatus === "AwaitingPayment" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ isPaymentPage: true })}
                  >
                    Pay Now
                  </Button>
                )}

                <SMiddle>
                  <h4>Guests:</h4>
                </SMiddle>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <SHeader80>Name</SHeader80>
                      <SHeaderRight>Total</SHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    {order.guests?.map(person => (
                      <Fragment key={`${person.lastName}.${person.firstName}`}>
                        <tr>
                          <td>
                            <b>{`${person.lastName}, ${person.firstName} `}</b>
                            {person.isChild ? "(U/13)" : "(Adult)"}
                          </td>
                          <SValueCell value={person.guestTotal} />
                        </tr>
                        <tr>
                          <td colSpan="2">
                            <Container
                              style={{
                                backgroundColor: "#f0f0f0",
                                border: "solid #d1d1d1"
                              }}
                            >
                              <Row>
                                <Col xs="8">
                                  <strong>Item</strong>
                                </Col>
                                <Col xs="4" style={{ textAlign: "right" }}>
                                  <strong>Cost</strong>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs="8">Admin Fee</Col>
                                <Col xs="4" style={{ textAlign: "right" }}>
                                  R {person.adminFee}
                                </Col>
                              </Row>

                              {person.fridaySupperSelected ? (
                                <Row>
                                  <Col xs="8">Friday Supper</Col>
                                  <Col xs="4" style={{ textAlign: "right" }}>
                                    R {person.fridaySupperPrice}
                                  </Col>
                                </Row>
                              ) : null}
                              {person.saturdayLunchSelected ? (
                                <Row>
                                  <Col xs="8">Saturday Lunch</Col>
                                  <Col xs="4" style={{ textAlign: "right" }}>
                                    R {person.saturdayLunchPrice}
                                  </Col>
                                </Row>
                              ) : null}
                              {person.saturdaySupperSelected ? (
                                <Row>
                                  <Col xs="8">Saturday Supper</Col>
                                  <Col xs="4" style={{ textAlign: "right" }}>
                                    R {person.saturdaySupperPrice}
                                  </Col>
                                </Row>
                              ) : null}
                              {person.sundayLunchSelected ? (
                                <Row>
                                  <Col xs="8">Sunday Lunch</Col>
                                  <Col xs="4" style={{ textAlign: "right" }}>
                                    R {person.sundayLunchPrice}
                                  </Col>
                                </Row>
                              ) : null}

                              {person.allergies && (
                                <p>
                                  <strong>
                                    * Allergies: {person.allergies}
                                  </strong>
                                </p>
                              )}
                            </Container>
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                    <tr>
                      <SHeaderRight>Total:</SHeaderRight>
                      <TotalCell value={order.guestTotal} />
                    </tr>
                  </tbody>
                </Table>
                <SMiddle>
                  <h4>Goods:</h4>
                </SMiddle>
                <Table bordered>
                  <thead>
                    <tr>
                      <SHeaderGoods>Souvenirs</SHeaderGoods>
                      <th>Price</th>
                      <th>Qty</th>
                      <SHeaderRight>Total</SHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    {order.goods?.map(souvenir => (
                      <tr key={souvenir.souvenirDescription}>
                        <td>{souvenir.souvenirDescription}</td>
                        <SValueCell value={souvenir.souvenirPrice} />
                        <SCellRight>
                          {souvenir.souvenirOrderQuantity}
                        </SCellRight>
                        <SValueCell value={souvenir.souvenirOrderItemTotal} />
                      </tr>
                    ))}
                    <tr>
                      <SHeaderRight colSpan="3">Total:</SHeaderRight>
                      <TotalCell value={order.goodsTotal} />
                    </tr>
                  </tbody>
                </Table>
                <SMiddle>
                  <h4>Summary:</h4>
                </SMiddle>
                <Table bordered>
                  <thead>
                    <tr>
                      <SHeader80 colSpan="2">Totals</SHeader80>
                      <SHeaderRight></SHeaderRight>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2">Atendees:</td>
                      <SValueCell value={order.guestTotal} />
                    </tr>
                    <tr>
                      <td colSpan="2">Souvenirs:</td>
                      <SValueCell value={order.goodsTotal} />
                    </tr>
                    <tr>
                      <SHeaderRight colSpan="2">Total:</SHeaderRight>
                      <TotalCell value={order.orderTotal} />
                    </tr>
                  </tbody>
                </Table>
              </Fragment>
            ) : (
              <Fragment>
                There was an error fetching this data
                {error && (
                  <div className={"alert alert-danger"}>{error.message}</div>
                )}
              </Fragment>
            )}
          </Fragment>
        ) : (
          <PayFastRedirectPage
            orderTotal={order.orderTotal}
            orderId={orderId}
            {...this.props}
          />
        )}
      </Fragment>
    );
  }
}

export default OrderPage;
