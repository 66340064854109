////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const HOME = "/";
export const ORDER = "/order";
export const CONTACT = "/contact";

////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// AUTH ROUTES //////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const PASSWORD_FORGET = "/forgot-password";
export const PASSWORD_RESET = "/reset-password";
export const CONFIRMATION = `/confirmation`;
export const CONFIRMATION_RESEND = `/confirmation-resend`;

////////////////////////////////////////////////////////////////////////////////
///////////////////////////////// ADMIN ROUTES /////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const ADMIN = "/admin/dashboard";
export const ADMIN_SOUVENIRS = "/admin/souivenirs";
export const ADMIN_MEALS = "/admin/meals";
export const ADMIN_EMAIL_TEST = "/admin/email-test";

////////////////////////////////////////////////////////////////////////////////
/////////////////////////////// INFOMATION ROUTES //////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const ACCOMODATION = "/accomodation";
export const ACTIVITIES = "/activites";
export const PACKING = "/packing";
export const PROGRAMME = "/programme";

////////////////////////////////////////////////////////////////////////////////
//////////////////////////////// ACCOUNT ROUTES ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const ACCOUNT = "/account";
export const ACCOUNT_ORDER = `${ACCOUNT}/order/:orderId`;
export const ACCOUNT_ORDER_ID = id => `${ACCOUNT}/order/${id}`;
export const ACCOUNT_ORDERS = `${ACCOUNT}/orders`;

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
