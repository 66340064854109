import React, { Fragment } from "react";
import { Wizard } from ".";
import { Table } from "reactstrap";
import { costs } from "../../constants";
import styled from "styled-components";

const SHeaderWide = styled.th`
  width: 50%;
`;

const SHeaderRight = styled.th`
  text-align: right;
`;
const SCellRight = styled.td`
  text-align: right;
`;

const SValueCell = ({ value }) => {
  return isNaN(value) ? (
    <SCellRight>R - </SCellRight>
  ) : (
    <SCellRight>R {value.toFixed(2)}</SCellRight>
  );
};

const InformationStep = () => (
  <Fragment>
    <Wizard.Page>
      <div>
        <h2>How it works: </h2>
        <ol>
          <li>Let us know who is attending</li>
          <li>Add some souvenirs to your order</li>
          <li>Confirm your order</li>
          <li>You will then be sent to the payment portal.</li>
        </ol>
        <p>
          If you're not attending the reunion, but would like to order
          souvenirs, please click "remove this guest" on the next page (Step 1)
          and then click "Next"
        </p>
        <h6>PLEASE RSVP / PLACE ORDERS BY THURSDAY, 26 MARCH 2020</h6>
        <br></br>
        <h4>Guest Costs:</h4>
        Prices as follows:
        <Table>
          <thead>
            <tr>
              <SHeaderWide />
              <SHeaderRight>Children (U/13)</SHeaderRight>
              <SHeaderRight>Adults</SHeaderRight>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Admin Fee:</td>
              <SValueCell value={costs.adminFee} />
              <SValueCell value={costs.adminFee} />
            </tr>
            <tr>
              <td>Lunch:</td>
              <SValueCell value={costs.childLunch} />
              <SValueCell value={costs.adultLunch} />
            </tr>
            <tr>
              <td>Supper:</td>
              <SValueCell value={costs.childSupper} />
              <SValueCell value={costs.adultSupper} />
            </tr>
          </tbody>
        </Table>
      </div>
    </Wizard.Page>
  </Fragment>
);

export default InformationStep;
