import React, { Fragment } from "react";
import { SImage, SMiddle, SHeader } from "../StyledComponents";
import { Container, Row, Col } from "reactstrap";

const HomePage = () => (
  <Fragment>
    <SMiddle>
      <SImage src="Images/Home/SoutheyCrest.jpg" alt="SoutheyCrest"></SImage>
    </SMiddle>

    <SHeader>Invitation</SHeader>

    <Container>
      <Row>
        <Col className={"text-center"}>
          <h1 style={{ color: "red" }}>The Event has been Postponed</h1>
          <p>
            <b>
              Due to the Coronavirus and limitation of gatherings, we have
              decided to postpone the event to a later date.
            </b>
          </p>
          <p>
            <b>
              <i>Please check your email for updates</i>
            </b>
          </p>
          {/*           
          <br />
          <h2>Southey Family Reunion</h2>
          <h3>1 - 3 May 2020</h3>
          <h5>Hillston Farm, Middelburg, Eastern Cape, South Africa</h5>
          <p>
            <b></b>
            <b></b>
            200 Years ago our ancestors left Britain headed for a country far
            away that they knew nothing or only a little about, but with a hope
            and a dream to make a life for themselves and their families in this
            far-off country. They landed in Algoa Bay, now Port Elizabeth, on 27
            April 1820 so it is only apt to celebrate our heritage in South
            Africa on the weekend of 1 - 3 May 2020.
            <br></br>
            <br></br>
            <strong>You're invited</strong> to come and join us on Hillston
            Farm, one of our family farms, in the Middelburg district of the
            Karoo, Eastern Cape, South Africa.
            <br></br>
            <br></br>
            We'll be having all our meals and activities on the farm.  However,
            you're welcome to arrange your own private tours with the owners of
            the other family farms in the district.
            <br></br>
            <br></br>
            Please register to sign into the Southey Family Reunion site where
            you can:
            <br></br>
            <br></br>
            RSVP for the reunion
            <br></br>
            View the Programme
            <br></br>
            Purchase Souvenirs
            <br></br>
            Make your payment
            <br></br>
          </p>
          <br></br>
          <h4>
            <i>We look forward to seeing you there!</i>
          </h4>
          <br></br>
          <h4>
            <strong>
              <i>Celebrating 200 Years 1820-2020</i>
            </strong>
          </h4>
          <br></br>
          <h5>Please RSVP before Thursday, 26 March 2020</h5>
          <br></br>
          <hr />   
            <SImage src="Images/Home/SoutheyCollage.jpg" />
*/}
        </Col>
      </Row>
    </Container>
  </Fragment>
);

export default HomePage;
