import React, { Fragment } from "react";
import { Table, Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { Wizard } from ".";
import { SHeader } from "../StyledComponents";
import { useFormikContext } from "formik";

const SHeaderRight = styled.th`
  text-align: right;
`;

const SHeader80 = styled.th`
  width: 80%;
`;

const SHeaderCell = styled.th`
  width: 50%;
`;

const SCell = styled.td``;

const SValueCell = styled.td`
  text-align: right;
`;

const ValueCell = ({ value }) => {
  return <SValueCell>R {value.toFixed(2)}</SValueCell>;
};

const StepCheckout = () => {
  const { values } = useFormikContext();

  values.orderTotal = values.goodsTotal + values.guestTotal;

  return (
    <Fragment>
      <Wizard.Page>
        <SHeader>Cart</SHeader>

        <p>
          <i>
            If your Cart Total comes to more than R 10 000 please split it so
            that any one transaction doesn't exceed R 10 000.
          </i>
        </p>
        <p>
          <strong>Your order looks as follows:</strong>
        </p>

        <h3>Attendees</h3>
        <Table bordered>
          <thead>
            <tr>
              <SHeader80>Attendee</SHeader80>
              <SHeaderRight>Attendee Subtotal</SHeaderRight>
            </tr>
          </thead>
          <tbody>
            {values.guests.map((guest, index) => (
              <Fragment key={index}>
                <tr>
                  <SCell>
                    {`${guest.lastName}, ${guest.firstName} (${
                      guest.isChild ? "U/13" : "Adult"
                    })`}
                  </SCell>
                  <ValueCell value={guest.guestTotal} />
                </tr>

                <tr>
                  <td colSpan="2">
                    <Container
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "solid #d1d1d1"
                      }}
                    >
                      <Row>
                        <Col xs="8">
                          <strong>Item</strong>
                        </Col>
                        <Col xs="4" style={{ textAlign: "right" }}>
                          <strong>Cost</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="8">Admin Fee</Col>
                        <Col xs="4" style={{ textAlign: "right" }}>
                          R {guest.adminFee}
                        </Col>
                      </Row>

                      {guest.fridaySupperSelected ? (
                        <Row>
                          <Col xs="8">Friday Supper</Col>
                          <Col xs="4" style={{ textAlign: "right" }}>
                            R {guest.fridaySupperPrice}
                          </Col>
                        </Row>
                      ) : null}
                      {guest.saturdayLunchSelected ? (
                        <Row>
                          <Col xs="8">Saturday Lunch</Col>
                          <Col xs="4" style={{ textAlign: "right" }}>
                            R {guest.saturdayLunchPrice}
                          </Col>
                        </Row>
                      ) : null}
                      {guest.saturdaySupperSelected ? (
                        <Row>
                          <Col xs="8">Saturday Supper</Col>
                          <Col xs="4" style={{ textAlign: "right" }}>
                            R {guest.saturdaySupperPrice}
                          </Col>
                        </Row>
                      ) : null}
                      {guest.sundayLunchSelected ? (
                        <Row>
                          <Col xs="8">Sunday Lunch</Col>
                          <Col xs="4" style={{ textAlign: "right" }}>
                            R {guest.sundayLunchPrice}
                          </Col>
                        </Row>
                      ) : null}

                      {guest.allergies && (
                        <p>
                          <strong>* Allergies: {guest.allergies}</strong>
                        </p>
                      )}
                    </Container>
                  </td>
                </tr>
              </Fragment>
            ))}
            <tr>
              <SCell>Total</SCell>
              <ValueCell value={values.guestTotal} />
            </tr>
          </tbody>
        </Table>

        <h3>Goods</h3>
        <Table bordered>
          <thead>
            <tr>
              <SHeaderCell>Item</SHeaderCell>
              <SHeaderRight>Price</SHeaderRight>
              <SHeaderRight>Qty</SHeaderRight>
              <SHeaderRight>Line Total</SHeaderRight>
            </tr>
          </thead>
          <tbody>
            {values.goods
              .filter(s => s.souvenirOrderQuantity > 0)
              .map((souvenir, index) => (
                <tr key={index}>
                  <SCell>{souvenir.souvenirTitle}</SCell>
                  <ValueCell value={souvenir.souvenirPrice} />
                  <SValueCell>{souvenir.souvenirOrderQuantity}</SValueCell>
                  <ValueCell value={souvenir.souvenirTotal} />
                </tr>
              ))}
            <tr>
              <SCell colSpan={3}>Total</SCell>
              <ValueCell value={values.goodsTotal} />
            </tr>
          </tbody>
        </Table>

        <h3>Totals</h3>
        <Table bordered>
          <thead>
            <tr>
              <SHeader80>Item</SHeader80>
              <SHeaderRight>Total</SHeaderRight>
            </tr>
          </thead>

          <tbody>
            <tr>
              <SCell>People</SCell>
              <ValueCell value={values.guestTotal} />
            </tr>
            <tr>
              <SCell>Goods</SCell>
              <ValueCell value={values.goodsTotal} />
            </tr>
            <tr>
              <SCell>Grand Total</SCell>
              <ValueCell value={values.orderTotal} />
            </tr>
          </tbody>
        </Table>
      </Wizard.Page>
    </Fragment>
  );
};

export default StepCheckout;
