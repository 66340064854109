//import config from "config";
import authHeader from "../helpers/auth-header";
import { handleResponse } from "../helpers/handle-response";

const apiUrl = process.env.SOUTHEY_REUNION_API_URL || "http://localhost:4000";

export const userService = {
  getAll
};

function getAll() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${apiUrl}/users`, requestOptions).then(handleResponse);
  //return fetch(`${config.apiUrl}/users`, requestOptions).then(handleResponse);
}
