import styled from "styled-components";

export const SImage = styled.img`
  max-width: 100%;
`;

export const SImageRounded = styled.img`
  max-width: 100%;
  border-radius: 20%;
`;

export const SImageFullWidth = styled.img`
  width: 100%;
`;

export const SMiddle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const SHeader = styled.h1`
  font-size: 2.5em;
  text-align: center;
  font-family: "Times New Roman", cursive, sans-serif;
`;
