import React, { Component, Fragment } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import * as ROUTES from "../../constants/routes";
import { authenticationService } from "../../services";
import roles from "../../constants/roles";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleAdminDropdown = this.toggleAdminDropdown.bind(this);
    this.toggleAccountDropdown = this.toggleAccountDropdown.bind(this);
    this.toggleInformationDropdown = this.toggleInformationDropdown.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      collapsed: true,
      adminDropdownOpen: false,
      accountDropdownOpen: false,
      informationDropdownOpen: false
    };
  }

  componentDidMount() {
    authenticationService.currentUser.subscribe(x =>
      this.setState({ currentUser: x })
    );
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleAdminDropdown() {
    this.setState({
      adminDropdownOpen: !this.state.adminDropdownOpen
    });
  }
  toggleAccountDropdown() {
    this.setState({
      accountDropdownOpen: !this.state.accountDropdownOpen
    });
  }

  toggleInformationDropdown() {
    this.setState({
      informationDropdownOpen: !this.state.informationDropdownOpen
    });
  }

  logout() {
    authenticationService.logout();
  }

  render() {
    const { currentUser } = this.state;

    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          <Container>
            <NavbarBrand tag={Link} to="/">
              2020 Southey Reunion
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                {currentUser?.role === roles.Admin ? (
                  <DropdownAdmin
                    isOpen={this.state.adminDropdownOpen}
                    toggle={this.toggleAdminDropdown}
                  />
                ) : null}

                <DropdownInformation
                  nav
                  isOpen={this.state.informationDropdownOpen}
                  toggle={this.toggleInformationDropdown}
                />

                <NavItem>
                  <NavLink
                    disabled
                    tag={Link}
                    className="text-gray"
                    to={ROUTES.ORDER}
                  >
                    <strike>RSVP</strike>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink tag={Link} className="text-dark" to={ROUTES.CONTACT}>
                    Contact
                  </NavLink>
                </NavItem>

                {currentUser ? (
                  <DropdownUser
                    user={currentUser}
                    isOpen={this.state.accountDropdownOpen}
                    toggle={this.toggleAccountDropdown}
                    logout={this.logout}
                  />
                ) : (
                  <DropdownSignIn
                    isOpen={this.state.accountDropdownOpen}
                    toggle={this.toggleAccountDropdown}
                  />
                )}
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

const DropdownUser = ({ user, toggle, isOpen, logout }) => {
  const { firstName } = user;
  return (
    <Fragment>
      <Dropdown nav isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret className="text-dark">
          {firstName}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" href={ROUTES.ACCOUNT_ORDERS}>
            Orders
          </DropdownItem>

          {/* <DropdownItem tag="a" href={ROUTES.ACCOUNT}>
            Account
          </DropdownItem> */}

          <DropdownItem tag="a" href={"/"}>
            <div onClick={logout}>Logout</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};

const DropdownSignIn = ({ toggle, isOpen }) => {
  return (
    <Fragment>
      <Dropdown nav isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret className="text-dark">
          Sign in / Register
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" href={ROUTES.SIGN_IN}>
            Sign In
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.SIGN_UP}>
            Register
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};

const DropdownInformation = ({ toggle, isOpen }) => {
  return (
    <Fragment>
      <Dropdown nav isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret className="text-dark">
          Information
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" href={ROUTES.HOME}>
            Home
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.ACCOMODATION}>
            Accomodation
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.PROGRAMME}>
            Programme
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.ACTIVITIES}>
            Activities
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.PACKING}>
            What to Pack
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};

const DropdownAdmin = ({ toggle, isOpen }) => {
  return (
    <Fragment>
      <Dropdown nav isOpen={isOpen} toggle={toggle}>
        <DropdownToggle nav caret className="text-dark">
          Admin
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag="a" href={ROUTES.ADMIN}>
            Admin Page
          </DropdownItem>

          <DropdownItem disabled>Guest List</DropdownItem>

          <DropdownItem tag="a" href={ROUTES.ADMIN_SOUVENIRS}>
            Souvenir Orders
          </DropdownItem>

          <DropdownItem tag="a" href={ROUTES.ADMIN_MEALS}>
            Meal Orders
          </DropdownItem>

          <DropdownItem disabled>Manage Users</DropdownItem>

          <DropdownItem tag="a" href={ROUTES.ADMIN_EMAIL_TEST}>
            Test Emails
          </DropdownItem>

          <DropdownItem disabled>
            <hr />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  );
};
