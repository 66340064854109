import React, { Fragment } from "react";
import { SImage, SHeader } from "../../StyledComponents";
import { Container, Row, Col } from "reactstrap";

const ProgrammePage = () => (
  <Fragment>
    <SImage src="Images/Programme/Programme2.jpg" alt="Programme"></SImage>
    <br></br>
    <br></br>
    <SHeader>Programme</SHeader>

    <br></br>
    <Container>
      <Row>
        <Col className={"text-center"}>
          <h1 style={{ color: "red" }}>The Event has been Postponed</h1>
          <p>
            <b>
              Due to the Coronavirus and limitation of gatherings, we have
              decided to postpone the event to a later date. The dates here will
              need to be updated
            </b>
          </p>
          <p>
            <b>
              <i>Please check your email for updates</i>
            </b>
          </p>
          <hr />
          <u>
            <h3>Friday, 1 May 2020</h3>
          </u>
          <br></br>
          <h5>16:00 Registration and Afternoon Tea</h5>
          <h5>18:30 Welcome Dinner</h5>
          <br></br>
          <u>
            <h3>Saturday, 2 May 2020</h3>
          </u>
          <br></br>
          <p>
            <i>Breakfast to be organised by yourselves</i>
            <br />
            <br></br>
          </p>
          <h5>10:00 Morning Tea</h5>
          <p>
            Spend the day on Hillston Farm where you can either hike / bike /
            bird / climb Hillstonkop <br></br>
            or any of the other kopje's / relax on the lawn and catch up with
            family
            <br />
            <br></br>
            Optional Family Farm visits in the morning (organise directly with
            the farmers):
            <br></br>
            Mt. Melsetter<br></br>
            Manor Holme<br></br>
            Hillmoor<br></br>
            Culmstock<br></br>
            <br></br>
            Optional Karoo River Rafting (organise directly with James Jordaan)
            <br />
            <br></br>
          </p>
          <h5>12:30 Lunch</h5>
          <p>
            Spend the afternoon doing various activites at Hillston
            <br></br>
            Optional Tennis / Bowls at the Schoombee Country Club
            <br />
            <br></br>
          </p>
          <h5>16:00 Afternoon Tea</h5>
          <br />
          <h5>18:30 Farewell Dinner</h5>
          <br />
          <u>
            <h3>Sunday, 3 May 2020</h3>
          </u>
          <br></br>
          <p>
            <i>Breakfast to be organised by yourselves</i>
            <br />
            <br></br>
          </p>
          <h5>09:30 Church Service at Hillston</h5>
          <br />
          <h5>10:30 Morning Tea</h5>
          <br />
          <h5>12:30 Lunch</h5>
          <p>
            Spend the day on Hillston Farm where you can either hike / bike /
            bird / climb Hillstonkop <br></br>
            or any of the other kopje's / relax on the lawn and catch up with
            family
            <br></br>
            <br></br>
          </p>
          <h5>16:00 Afternoon Tea</h5>
          <br />
          <h5>
            <i>Safe Travelling Home</i>
          </h5>
        </Col>
      </Row>
    </Container>
  </Fragment>
);

export default ProgrammePage;
