const activityData = [
  {
    img: "Images/Activities/Hiking.jpg",
    title: "Climb the mountain / kopje's",
    detail: "Saturday and Sunday at Hillston Farm",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  },
  {
    img: "Images/Activities/Walking.jpg",
    title: "Go for a walk on the farm / cycle the farm tracks",
    detail: "Saturday and Sunday at Hillston Farm",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  },
  {
    img: "Images/Activities/Relax.jpg",
    title: "Relax at Hillston Farm",
    detail: "Saturday and Sunday",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  },
  {
    img: "Images/Activities/boardgames.jpg",
    title: "Play boardgames / lawngames",
    detail: "Saturday / Sunday",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  },
  {
    img: "Images/Activities/familyfarms.jpg",
    title: "Visit Manor Holme",
    detail: "Saturday Morning",
    contact: "Julian Southey 082 579 0488 / julian@adsactive.com"
  },
  {
    img: "Images/Activities/MtMelsetter.jpg",
    title: "Visit Mt. Melsetter",
    detail: "Saturday Morning",
    contact: "Mike Ferrar 083 303 7625 / info@mountmelsetter.co.za"
  },
  {
    img: "Images/Activities/Hillmoor.jpg",
    title: "Visit Hillmoor",
    detail: "Saturday Morning",
    contact: "Brenda Dell 082 926 8990 / bjd@nokwi.co.za"
  },
  {
    img: "Images/Activities/Culmstock.jpg",
    title: "Visit Culmstock",
    detail: "Saturday Morning",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  },
  {
    img: "Images/Activities/KarooRiverRafting.jpg",
    title: "Karoo River Rafting",
    detail: "Saturday Morning",
    contact: "James Jordaan 084 429 9944 / info@karoo-river-rafting.co.za"
  },
  {
    img: "Images/Activities/Tennis.jpg",
    title: "Optional Tennis / Bowls at Schoombee Country Club",
    detail: "Saturday Afternoon",
    contact:
      "Christine MacLachlan 082 322 4371 / southeyfamilyreunion@gmail.com"
  }
];

export default activityData;
