export const API_BASE_URL =
  process.env.REACT_APP_API_URL ||
  "https://api-southey-reunion-prod.herokuapp.com";

//USERS:
export const USER_REGISTER = `${API_BASE_URL}/users/register`;
export const USER_AUTHENTICATE = `${API_BASE_URL}/users/authenticate`;
export const USER_CONFIMATION = `${API_BASE_URL}/users/confirmation`;
export const USER_CONFIMATION_RESEND = `${API_BASE_URL}/users/resend-confirmation`;
export const USER_UPDATE = `${API_BASE_URL}/users/update`;
export const USER_PASSWORD_FORGOT = `${API_BASE_URL}/users/forgot-password`;
export const USER_PASSWORD_RESET = `${API_BASE_URL}/users/reset-password`;

export const ADMIN_TEST_EMAIL = `${API_BASE_URL}/users/testEmail`;

export const GET_USER_ORDERS = `${API_BASE_URL}/orders`;
export const GET_USER_ORDER = orderId => `${API_BASE_URL}/orders/${orderId}`;

export const CREATE_ORDER = `${API_BASE_URL}/orders`;

export const GET_SOUVENIRNS = `${API_BASE_URL}/souvenirs`;

export const ITN_URL = `${API_BASE_URL}/itn`;
