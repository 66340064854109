import React, { Component } from "react";
import { SHeader } from "../../StyledComponents";
import { Table, Row, Col, Container } from "reactstrap";
import styled from "styled-components";
import Axios from "axios";
import { GET_SOUVENIRNS } from "../../../constants/apiUrls";

const SCellRight = styled.td`
  text-align: right;
  white-space: pre;
`;
const SHeaderRight = styled.th`
  text-align: right;
  white-space: pre;
`;

const SHeader60 = styled.th`
  width: 60%;
`;

const SValueCell = ({ value }) => {
  return <SCellRight>R {value?.toFixed(2) || "-"}</SCellRight>;
};

const totalsData = [
  {
    item: "Beer Cozy",
    itemQuantity: 293
  },
  {
    item: "Table Cloth",
    itemQuantity: 5
  }
];

const distributionList = [
  {
    person: "MacLachlan, Kyle",
    mobile: "0829237785",
    order: [
      {
        item: "Beer Cozy",
        itemQuantity: 6
      },
      {
        item: "Table Cloth",
        itemQuantity: 1
      }
    ]
  },
  {
    person: "MacLachlan, Chistine",
    mobile: "0823224371",
    order: [
      {
        item: "Table Cloth",
        itemQuantity: 1
      }
    ]
  }
];

class AdminSouvenirsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      souvenirs: [],
      isSouvenirOptionsLoading: true
    };
  }
  getSouvenirs() {
    Axios.get(GET_SOUVENIRNS)
      .then(response => response.data)
      .then(souvenirs => {
        this.setState({
          isSouvenirOptionsLoading: false,
          souvenirs: souvenirs
        });
      })
      .catch(error =>
        this.setState({ error, isSouvenirOptionsLoading: false })
      );
  }

  componentDidMount() {
    this.getSouvenirs();
  }

  render() {
    return (
      <div>
        <SHeader>Souvenir Dashboard</SHeader>

        <h4>Meal Options:</h4>
        <p>The options that we have for Sounvenirs</p>
        {!this.state.isSouvenirOptionsLoading ? (
          <Table>
            <thead>
              <tr>
                <SHeader60>Souvenir</SHeader60>
                <SHeaderRight>Price</SHeaderRight>
              </tr>
            </thead>
            <tbody>
              {this.state.souvenirs.map((souvenir, index) => (
                <tr key={index}>
                  <td>{souvenir.souvenirDescription}</td>
                  <SValueCell value={souvenir.souvenirPrice} />
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Loading</p>
        )}

        <h4>TODO: Item Totals:</h4>
        <p>The total number of each item ordered.</p>
        <Table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            {totalsData.map(totals => (
              <tr>
                <td>{totals.item}</td>
                <td>{totals.itemQuantity}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <h4>TODO: Distribution List:</h4>
        <p>How many each person gets</p>
        <Table>
          {distributionList.map(list => (
            <tbody>
              <tr>
                <td>{list.person}</td>
                <td>{list.mobile}</td>
              </tr>
              <tr>
                <td colSpan="2">
                  <Container
                    style={{
                      backgroundColor: "#f0f0f0",
                      border: "solid #d1d1d1"
                    }}
                  >
                    {list.order.map(odr => (
                      <Row style={{ border: "solid #d1d1d1" }}>
                        <Col>{odr.item}</Col>
                        <Col>{odr.itemQuantity}</Col>
                      </Row>
                    ))}
                  </Container>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
    );
  }
}

export default AdminSouvenirsPage;
