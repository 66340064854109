import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../helpers/handle-response";
import { USER_AUTHENTICATE } from "../constants/apiUrls";
import Axios from "axios";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  loginAxios,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function loginAxios(username, password) {
  Axios.post(USER_AUTHENTICATE, {
    username,
    password
  })
    .then(function(response) {
      console.log("AXRES", JSON.stringify(response, null, 2));
      /*
      AXRES {
  "data": {
    "id": 1,
    "username": "kylemac06@gmail.com",
    "firstName": "Kyle",
    "lastName": "MacLachlan",
    "role": "Admin",
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjEiLCJyb2xlIjoiQWRtaW4iLCJuYmYiOjE1ODI1OTIxNjMsImV4cCI6MTU4MzE5Njk2MywiaWF0IjoxNTgyNTkyMTYzfQ.8ed9e8DxBrVYTNzI7eU2G-uQok_9WR0ya2n3kPJoxCY"
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
    "content-type": "application/json; charset=utf-8"
  },
  "config": {
    "url": "http://localhost:5000/users/authenticate",
    "method": "post",
    "data": "{\"username\":\"kylemac06@gmail.com\",\"password\":\"kbm006\"}",
    "headers": {
     --------------------------
      "Content-Type": "application/json;charset=utf-8"
    },
    "transformRequest": [
      null
    ],
    "transformResponse": [
      null
    ],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1
  },
  "request": {
    "__sentry_xhr__": {
      "method": "POST",
      "url": "http://localhost:5000/users/authenticate"
    }
  }
}

*/
    })
    .catch(function(error) {
      console.log("AXERR: ", JSON.stringify(error, null, 2));
      /*
      
      AXERR:  {
  "message": "Network Error",
  "name": "Error",
  "stack": "Error: Network Error\n    at createError (http://localhost:3001/static/js/0.chunk.js:13130:15)\n    at XMLHttpRequest.handleError (http://localhost:3001/static/js/0.chunk.js:12625:14)\n    at XMLHttpRequest.sentryWrapped (http://localhost:3001/static/js/0.chunk.js:4398:17)",
  "config": {
    "url": "https://api-southey-reunion-dev.herokuapp.com/users/authenticate",
    "method": "post",
    "data": "{\"username\":\"kylemac06@gmail.com\",\"password\":\"kbm006\"}",
    "headers": {
      "Accept": "application/json, text/plain, *",
      "Content-Type": "application/json;charset=utf-8"
    },
    "transformRequest": [
      null
    ],
    "transformResponse": [
      null
    ],
    "timeout": 0,
    "xsrfCookieName": "XSRF-TOKEN",
    "xsrfHeaderName": "X-XSRF-TOKEN",
    "maxContentLength": -1
  }
}
      
      */
    });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  // TODO: change to axios
  return fetch(USER_AUTHENTICATE, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    });
}

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  // TODO: change to axios
  return fetch(USER_AUTHENTICATE, requestOptions)
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
}
