import React, { Component, Fragment } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { Button, Container, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { SHeader } from "../../StyledComponents";
import { ADMIN_TEST_EMAIL } from "../../../constants/apiUrls";
import Axios from "axios";
import { authenticationService } from "../../../services";
import authHeader from "../../../helpers/auth-header";

class EmailTestPage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <SHeader>Email Test Page:</SHeader>

        <Formik
          initialValues={{
            to: authenticationService.currentUserValue?.username || "",
            subject: "TEST EMAIL",
            message: ""
          }}
          onSubmit={(fields, { setStatus, setSubmitting }) => {
            alert(JSON.stringify(fields, null, 2));
            Axios.post(
              ADMIN_TEST_EMAIL,
              {
                to: fields.to,
                subject: fields.subject,
                message: fields.message
              },
              { headers: authHeader() }
            )
              .then(response => {
                this.setState({ isLoading: false, success: true });
              })
              .catch(error => {
                this.setState({
                  error: error.response?.data?.message,
                  isLoading: false
                });
              });
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            to: Yup.string().required("Required"),
            subject: Yup.string().required("Required"),
            message: Yup.string().required("Required")
          })}
        >
          {({ values, errors, status, touched, isSubmitting }) => (
            <div>
              <Form>
                <div className="form-group">
                  <label htmlFor="to">To</label>
                  <Field
                    name="to"
                    type="text"
                    className={
                      "form-control" +
                      (errors.to && touched.to ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="to"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <Field
                    name="subject"
                    type="text"
                    className={
                      "form-control" +
                      (errors.subject && touched.subject ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="subject"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="message">Message</label>
                  <Field
                    name="message"
                    component="textarea"
                    rows="20"
                    className={
                      "form-control" +
                      (errors.message && touched.message ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <br />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Send test email
                </Button>
                {status && <div className={"alert alert-danger"}>{status}</div>}
              </Form>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

export default EmailTestPage;
