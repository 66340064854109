import React, { Component, Fragment } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { Button, Container, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { SHeader } from "../../../StyledComponents";
import Axios from "axios";
import { authenticationService } from "../../../../services";
import { USER_CONFIMATION } from "../../../../constants/apiUrls";
import authHeader from "../../../../helpers/auth-header";

class UserDetailChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, error: null };
  }

  // updateUser() {
  //   Axios.get(GET_USER_ORDER(this.state.orderId), { headers: authHeader() })
  //     .then(response => response.data)
  //     .then(order => {
  //       this.setState({
  //         order,
  //         isLoading: false
  //       });
  //     })
  //     .catch(error => this.setState({ error, isLoading: false }));
  // }

  componentDidMount() {}

  render() {
    const { firstName, lastName } = authenticationService.currentUserValue;

    return (
      <Fragment>
        <Container>
          <Row>
            <Col>
              <hr />
              <SHeader>Update your details:</SHeader>
              <hr />
              <Formik
                initialValues={{
                  firstName: firstName,
                  lastName: lastName
                }}
                onSubmit={({ firstName, lastName }, { setSubmitting }) => {
                  const payload = { firstName, lastName };
                  Axios.put(USER_CONFIMATION, payload, {
                    headers: authHeader()
                  })
                    .then(response => {
                      this.setState({ isLoading: false });
                      setSubmitting(false);
                      //TODO: Handle response
                    })
                    .catch(error => this.setState({ error, isLoading: false }));
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("Required"),
                  lastName: Yup.string().required("Required")
                })}
              >
                {({ values, errors, status, touched, isSubmitting }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="firstName">First name</label>
                        <Field
                          name="firstName"
                          type="text"
                          className={
                            "form-control" +
                            (errors.firstName && touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="lastName">Last name</label>
                        <Field
                          name="lastName"
                          type="text"
                          className={
                            "form-control" +
                            (errors.lastName && touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Save Changes
                      </Button>
                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </Form>
                  </div>
                )}
              </Formik>
              <hr />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default UserDetailChangeForm;
