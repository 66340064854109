import React from "react";
import { SImage, SMiddle, SHeader } from "../StyledComponents";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";

const ContactPage = () => (
  <div>
    <SMiddle>
      <SImage src="Images/ContactUs/ContactUs.jpg" alt="ContactUs"></SImage>
    </SMiddle>
    <br></br>
    <SHeader>Contact Us</SHeader>

    <Container>
      <Row>
        <Col md="6">
          <h4 className={"text-center"}>Contact us:</h4>
          <p className={"text-center"}>
            <i className={"text-center"}>
              For more information / questions / queries please contact us
              below:
            </i>
          </p>
          <hr /> <h5>Riana Southey</h5>
          <FontAwesomeIcon icon={faPhone} size="1x" />
          <a href="tel: 0823224403"> 082 322 4403</a>
          <br />
          <FontAwesomeIcon icon={faEnvelope} size="1x" />
          <a href="mailto: info@hillstonfarm.co.za"> info@hillstonfarm.co.za</a>
          <hr />
          <h5>Christine MacLachlan</h5>
          <FontAwesomeIcon icon={faPhone} size="1x" />
          <a href="tel:  0823224371"> 082 322 4371</a>
          <br />
          <FontAwesomeIcon icon={faEnvelope} size="1x" />
          <a href="mailto: southeyfamilyreunion@gmail.com">
            {" "}
            southeyfamilyreunion@gmail.com
          </a>
          <hr />
          <FontAwesomeIcon icon={faFacebookSquare} size="1x" /> Join our
          <a href="https://www.facebook.com/groups/476489852452099/">
            {" "}
            Facebook page
          </a>
          <hr />
        </Col>
        <Col md="6" className={"text-center"}>
          <h4>Directions: </h4>
          <i> For directions to Hillston Farm, please click on this picture:</i>
          <a href="https://www.google.com/maps/dir//Hillston+Farm+Guest+House,+Schoombee,+Middelburg+-+Ec,+Middelburg+-+EC,+5900/@-31.398004,25.4929443,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x1e87dca114ea5657:0xb4455aafc4083f15!2m2!1d25.495133!2d-31.398004">
            <SImage
              src="Images/ContactUs/ContactUs2.jpg"
              alt="ContactUs2"
            ></SImage>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
);

export default ContactPage;
