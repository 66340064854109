import React, { Fragment } from "react";
import { SImage, SHeader } from "../../StyledComponents";
import cardData from "./accomodationData";
import {
  Card,
  CardImg,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  Button,
  CardText
} from "reactstrap";

const AccomodationPage = () => {
  return (
    <Fragment>
      <SImage
        src="Images/Accommodation/Accommodation.jpg"
        alt="Accommodation"
      />
      <br></br>
      <br></br>
      <SHeader>Accommodation</SHeader>
      <br></br>
      <Container>
        <Row>
          {cardData.map(card => (
            <Col key={card.img} sm="6">
              <Card
                style={{ backgroundColor: "#f7f7f7", borderColor: "#2b2b2b" }}
              >
                <CardHeader href={card.link} tag="h3">
                  {card.title}
                </CardHeader>
                <CardImg width="100%" src={card.img} alt={card.title} />
                <CardFooter>
                  {card.contact && (
                    <CardText>
                      <medium className="text-muted">
                        Contact: {card.contact}
                      </medium>
                    </CardText>
                  )}
                  <Button
                    style={{ width: "100%" }}
                    href={card.link}
                    target="_blank"
                    color="primary"
                  >
                    Visit Website
                  </Button>
                </CardFooter>
              </Card>
              <br />
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};

export default AccomodationPage;
