import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Container, Row, Col } from "reactstrap";

import { SignUpLink } from "../SignUp";
import { PasswordForgetLink } from "../PasswordForget";
import { authenticationService } from "../../../services/authentication.service";
import { SHeader } from "../../StyledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { ConfirmationResendLink } from "../ResendConfirmation";

class SignInPage extends Component {
  constructor(props) {
    super(props);

    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
              <FontAwesomeIcon icon={faUserLock} size="5x" />
              <SHeader>Login</SHeader>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <Formik
                initialValues={{
                  username: "",
                  password: ""
                }}
                onSubmit={(
                  { username, password },
                  { setStatus, setSubmitting }
                ) => {
                  setStatus();
                  // authenticationService.login(username, password).then(
                  authenticationService.loginAxios(username, password).then(
                    user => {
                      const { from } = this.props.location.state || {
                        from: { pathname: "/" }
                      };
                      this.props.history.push(from);
                    },
                    error => {
                      setSubmitting(false);
                      setStatus(error);
                    }
                  );
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string()
                    .email()
                    .required("Required"),
                  password: Yup.string().required("Required")
                })}
              >
                {({ values, errors, status, touched, isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label htmlFor="username">Email</label>
                      <Field
                        name="username"
                        type="text"
                        className={
                          "form-control" +
                          (errors.username && touched.username
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="username"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <Field
                        name="password"
                        type="password"
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Sign In
                    </Button>

                    <div className="form-group"></div>
                    {status && (
                      <div className={"alert alert-danger"}>{status}</div>
                    )}
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <ConfirmationResendLink />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <PasswordForgetLink />
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <SignUpLink />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const SignInForm = withRouter(SignInPage);

export default SignInForm;
