const accomodationData = [
  {
    title: "Northmead Country House",
    img: "Images/Accommodation/Northmead.jpg",
    link:
      "https://hillstonfarm.co.za/karoo-guest-farm-self-catering-accommodation/"
  },
  {
    title: "Karoo Ridge Conservancy ",
    img: "Images/Accommodation/KarooRidge.jpg",
    link: "https://karooridgeconservancy.com/accommodation/"
  },
  {
    title: "Waterlea Tented Camp",
    img: "Images/Accommodation/Waterlea.jpg",
    link:
      "https://www.travelground.com/accommodation/waterlea-on-river-tented-cabins"
  },
  {
    title: "Hopewell Farmstay",
    img: "Images/Accommodation/Hopewell.jpg",
    link: "https://hillstonfarm.co.za/karoo-guest-farm-hopewell/"
  },
  {
    title: "Croydon Cottage",
    img: "Images/Accommodation/CroydonCottage.jpg",
    link: "https://www.travelground.com/accommodation/croydon-cottage"
  },
  {
    title: "Jericho Game Farm",
    img: "Images/Accommodation/JerichoGameFarm.jpg",
    link: "https://www.jerichogamefarm.com/accomodation.html"
  },
  {
    title: "Elandskuil",
    img: "Images/Accommodation/Elandskuil.jpg",
    link: "https://www.safarinow.com/go/elandskuil-steynsburg-self-catering/"
  },
  {
    title: "Middelburg Karoo",
    img: "Images/Accommodation/Middelburg.jpg",
    link: "https://www.middelburgec.co.za/listings/accommodation/middelburg"
  },
  {
    title: "Lekkeslaap Middelburg",
    img: "Images/Accommodation/Lekkeslaap.jpg",
    link:
      "https://www.lekkeslaap.co.za/akkommodasie-in/middelburg-upper-karoo?q=middelburg-upper-karoo"
  },
  {
    title: "Steynsburg Accommodation",
    img: "Images/Accommodation/Steynsburg.jpg",
    link: "https://www.travelground.com/accommodation-in/steynsburg"
  }
];

export default accomodationData;
