import React, { Fragment } from "react";
import PasswordChangeForm from "./PasswordChange";
import { SHeader } from "../../StyledComponents";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons";
import UserDetailChangeForm from "./UpdateDetails";

const AccountManagePage = () => (
  <Fragment>
    <SHeader>Account Page</SHeader>
    <Container>
      <Row>
        <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
          <FontAwesomeIcon icon={faUsersCog} size="5x" />
        </Col>
      </Row>

      <Row>
        <Col lg={{ size: 6 }}>
          <UserDetailChangeForm />
        </Col>
        <Col lg={{ size: 6 }}>
          <PasswordChangeForm />
        </Col>
      </Row>
    </Container>
  </Fragment>
);

export default AccountManagePage;
