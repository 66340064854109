import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { SHeader } from "../../StyledComponents";
import * as Yup from "yup";
import { Container, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { USER_PASSWORD_FORGOT } from "../../../constants/apiUrls";
import Axios from "axios";

class PasswordForgetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
              <FontAwesomeIcon icon={faUserCircle} size="5x" />
              <SHeader>Password Reset</SHeader>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              {this.state.success ? (
                <Fragment>
                  <div className={"alert alert-success"}>
                    Check your email for a link to reset your password
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => this.props.history.push("/")}
                  >
                    HOME
                  </Button>
                </Fragment>
              ) : (
                <Formik
                  initialValues={{
                    username: ""
                  }}
                  onSubmit={({ username }, { setStatus, setSubmitting }) => {
                    Axios.post(USER_PASSWORD_FORGOT, {
                      username
                    })
                      .then(response => {
                        this.setState({ success: true });
                      })
                      .catch(error => {
                        setSubmitting(false);
                        console.log(JSON.stringify(error, null, 2));
                        setStatus(error.message || "There was an Error");
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string()
                      .email()
                      .required("Required")
                  })}
                >
                  {({ values, errors, status, touched, isSubmitting }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="username">Email</label>
                        <Field
                          name="username"
                          type="email"
                          required={true}
                          className={
                            "form-control" +
                            (errors.username && touched.username
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting || this.state.success}
                      >
                        Reset My Password
                      </Button>

                      <div className="form-group"></div>
                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET} variant="body2">
      Forgot Password?
    </Link>
  </p>
);
export default PasswordForgetPage;
