import React from "react";
import { Route } from "react-router-dom";
import SignUpPage from "../Authentication/SignUp";
import SignInPage from "../Authentication/SignIn";
import ConfimationPage from "../Authentication/Confirmation";
import ResendConfimationPage from "../Authentication/ResendConfirmation";
import PasswordForgetPage from "../Authentication/PasswordForget";
import ContactPage from "../Contact";
import HomePage from "../Home";
import AdminPage from "../Admin/Dashboard";
import * as ROUTES from "../../constants/routes";
import Orders from "../Orders";
import { Layout } from "./Layout";
import * as Sentry from "@sentry/browser";
import { PrivateRoute } from "../PrivateRoute";

// Indexed files to share
import Info from "../Information";
import AccountPages from "../Account";
import AdminSouvenirsPage from "../Admin/Souviner";
import AdminMealsPage from "../Admin/Meals";
import EmailTestPage from "../Admin/EmailTest";
import PasswordResetPage from "../Authentication/PasswordReset";

Sentry.init({
  dsn: "https://df68ead1d2884e98863a214983119420@sentry.io/2707272"
});

const App = () => (
  <Layout>
    <Route exact path={ROUTES.HOME} component={HomePage} />

    {/* Account Routes */}
    <PrivateRoute
      exact
      path={ROUTES.ACCOUNT}
      component={AccountPages.AccountPage}
    />
    <PrivateRoute
      exact
      path={ROUTES.ACCOUNT_ORDERS}
      component={AccountPages.OrdersPage}
    />
    <PrivateRoute
      exact
      path={ROUTES.ACCOUNT_ORDER}
      component={AccountPages.OrderPage}
    />

    {/* Information Routes */}
    <Route exact path={ROUTES.ACTIVITIES} component={Info.ActivitiesPage} />
    <Route exact path={ROUTES.PROGRAMME} component={Info.ProgrammePage} />
    <Route exact path={ROUTES.ACCOMODATION} component={Info.AccomodationPage} />
    <Route exact path={ROUTES.PACKING} component={Info.PackingPage} />

    {/* Admin Routes */}
    <PrivateRoute exact path={ROUTES.ADMIN} component={AdminPage} />
    <PrivateRoute
      exact
      path={ROUTES.ADMIN_SOUVENIRS}
      component={AdminSouvenirsPage}
    />
    <PrivateRoute exact path={ROUTES.ADMIN_MEALS} component={AdminMealsPage} />
    <PrivateRoute
      exact
      path={ROUTES.ADMIN_EMAIL_TEST}
      component={EmailTestPage}
    />

    {/* Auth Routes */}
    <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route exact path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
    <Route exact path={ROUTES.CONFIRMATION} component={ConfimationPage} />
    <Route
      exact
      path={ROUTES.CONFIRMATION_RESEND}
      component={ResendConfimationPage}
    />

    {/* Other Routes */}

    {/* <PrivateRoute exact path={ROUTES.ORDER} component={Orders} /> */}

    <Route exact path={ROUTES.CONTACT} component={ContactPage} />

    {/* TODO: Update this to a redirect or 404
    <Route path={"/"} component={SignInPage} />
    */}
  </Layout>
);

export default App;
