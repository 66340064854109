import React, { Fragment } from "react";
import { Field, useFormikContext } from "formik";
import { Wizard } from ".";
import { SHeader, SImageRounded } from "../StyledComponents";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import { FieldArray } from "formik";

const Step2 = () => {
  const { values } = useFormikContext();

  values.goods = values.goods.map(g => ({
    ...g,
    souvenirTotal: g.souvenirPrice * g.souvenirOrderQuantity
  }));

  values.goodsTotal = values.goods.reduce(
    (prev, cur) => prev + cur.souvenirTotal,
    0
  );

  values.orderTotal = values.guestTotal + values.goodsTotal;

  return (
    <Fragment>
      <Wizard.Page>
        <Fragment>
          <h2>Souvenirs</h2>
          <FieldArray
            name="souvenirs"
            render={arrayHelpers => (
              <Container>
                {values.goods.map((souvenir, index) => (
                  <Fragment key={index}>
                    <Row style={{ border: "solid #d1d1d1" }}>
                      <Col md="6">
                        <SImageRounded src={souvenir.souvenirImageURL} />
                      </Col>
                      <Col md="6">
                        <SHeader>{souvenir.souvenirTitle}</SHeader>
                        <p>{souvenir.souvenirDescription}</p>
                        <p>R {souvenir.souvenirPrice.toFixed(2)} ea</p>
                        <FormGroup>
                          <Label for="exampleSelect">
                            How many would you like?
                          </Label>
                          <Field
                            name={`goods[${index}].souvenirOrderQuantity`}
                            component="input"
                            type="number"
                            className={"form-control"}
                          />
                        </FormGroup>
                        <hr />
                        Total: R{" "}
                        {(
                          souvenir.souvenirPrice *
                          souvenir.souvenirOrderQuantity
                        ).toFixed(2)}
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Container>
            )}
          />
        </Fragment>
      </Wizard.Page>
    </Fragment>
  );
};

export default Step2;
