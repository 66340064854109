import React, { Component, Fragment } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { Button, Container, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { SHeader } from "../../StyledComponents";
import queryString from "query-string";
import { USER_PASSWORD_RESET } from "../../../constants/apiUrls";
import Axios from "axios";
import { SIGN_IN } from "../../../constants/routes";

class PasswordResetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      token: null,
      success: false
    };
  }

  componentDidMount() {
    const { userId, token } = queryString.parse(this.props.location.search);

    this.setState({
      userId,
      token
    });
  }

  render() {
    console.log("userId", this.state.userId);
    console.log("token", this.state.token);

    const hasEmptyDetails = !this.state.userId || !this.state.token;

    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <hr />
              <SHeader>Reset Password</SHeader>
              <hr />
              {this.state.success ? (
                <Fragment>
                  <div className={"alert alert-success"}>
                    We have updated your password, please try and login
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => this.props.history.push(SIGN_IN)}
                  >
                    Login
                  </Button>
                </Fragment>
              ) : (
                <Formik
                  initialValues={{
                    password: "",
                    passwordConfirmation: ""
                  }}
                  onSubmit={({ password }, { setStatus, setSubmitting }) => {
                    Axios.post(USER_PASSWORD_RESET, {
                      username: this.state.userId,
                      token: this.state.token,
                      password
                    })
                      .then(response => {
                        this.setState({ success: true });
                      })
                      .catch(error => {
                        setSubmitting(false);
                        console.log(JSON.stringify(error, null, 2));
                        setStatus(error.message || "There was an Error");
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string()
                      .required("Required")
                      .min(6),
                    passwordConfirmation: Yup.string().oneOf(
                      [Yup.ref("password"), null],
                      "Passwords must match"
                    )
                  })}
                >
                  {({ values, errors, status, touched, isSubmitting }) => (
                    <div>
                      <Form>
                        <div className="form-group">
                          <label htmlFor="password">New Password</label>
                          <Field
                            name="password"
                            type="password"
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="passwordConfirmation">
                            Confirm Password
                          </label>
                          <Field
                            name="passwordConfirmation"
                            type="password"
                            className={
                              "form-control" +
                              (errors.passwordConfirmation &&
                              touched.passwordConfirmation
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="passwordConfirmation"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <br />
                        {hasEmptyDetails && (
                          <div className={"alert alert-danger"}>
                            Token or Id missing
                          </div>
                        )}
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting || hasEmptyDetails}
                        >
                          Reset my Password
                        </Button>
                        {status && (
                          <div className={"alert alert-danger"}>{status}</div>
                        )}
                      </Form>
                    </div>
                  )}
                </Formik>
              )}
              <hr />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default PasswordResetPage;
