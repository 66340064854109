import React, { Fragment } from "react";
import cardData from "./cardData";
import { SHeader, SMiddle, SImage } from "../../StyledComponents";
import {
  Card,
  CardImg,
  CardText,
  CardTitle,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter
} from "reactstrap";

const ActivitesPage = () => {
  return (
    <Fragment>
      <SImage src="Images/Activities/Activities.jpg" alt="Activities" />
      <br></br>
      <br></br>
      <SHeader>Activities</SHeader>
      <SMiddle>
        <h5>
          Please note: all bookings for the Farm Vists and the Karoo River
          Rafting must be made before Monday, 6 April 2020
        </h5>
      </SMiddle>
      <br></br>
      <Container>
        <Row>
          {cardData.map(card => (
            <Col key={card.img} sm="6">
              <Card
                style={{ backgroundColor: "#f7f7f7", borderColor: "#2b2b2b" }}
              >
                <CardHeader>{card.title}</CardHeader>
                <CardImg width="100%" src={card.img} alt={card.title} />
                <CardBody>
                  <CardTitle>{card.detail}</CardTitle>
                  <CardText></CardText>
                </CardBody>
                <CardFooter>
                  {card.contact && (
                    <p className="text-muted">Contact: {card.contact}</p>
                  )}
                </CardFooter>
              </Card>
              <br />
            </Col>
          ))}
        </Row>
      </Container>
    </Fragment>
  );
};

export default ActivitesPage;
