import React, { Fragment } from "react";
import { Wizard } from ".";
import { FieldArray, useFormikContext, Field, ErrorMessage } from "formik";
import { Button, Container, Row, Col, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { costs } from "../../constants";
import { authenticationService } from "../../services";

export const initialGuestRow = {
  firstName: "",
  lastName: authenticationService.currentUserValue?.lastName || "",
  email: "",
  mobile: "",
  isChild: false,
  hasAllergies: false,
  allergies: "",
  fridaySupperSelected: true,
  fridaySupperPrice: costs.adultSupper,

  saturdayLunchSelected: true,
  saturdayLunchPrice: costs.adultLunch,

  saturdaySupperSelected: true,
  saturdaySupperPrice: costs.adultSupper,

  sundayLunchSelected: true,
  sundayLunchPrice: costs.adultLunch,
  guestTotal: 0
};

const calculateGuestTotal = guest => {
  const guestCost =
    (guest.fridaySupperSelected ? guest.fridaySupperPrice : 0) +
    (guest.saturdayLunchSelected ? guest.saturdayLunchPrice : 0) +
    (guest.saturdaySupperSelected ? guest.saturdaySupperPrice : 0) +
    (guest.sundayLunchSelected ? guest.sundayLunchPrice : 0) +
    guest.adminFee;

  return guestCost;
};

const Step1 = () => {
  const { values } = useFormikContext();

  values.guests = values.guests.map(g => ({
    ...g,
    adminFee: costs.adminFee,
    fridaySupperPrice: g.isChild ? costs.childSupper : costs.adultSupper,
    saturdayLunchPrice: g.isChild ? costs.childLunch : costs.adultLunch,
    saturdaySupperPrice: g.isChild ? costs.childSupper : costs.adultSupper,
    sundayLunchPrice: g.isChild ? costs.childLunch : costs.adultLunch
  }));

  values.guests = values.guests.map(g => ({
    ...g,
    guestTotal: calculateGuestTotal(g)
  }));

  values.guestTotal = values.guests.reduce(
    (prev, cur) => prev + cur.guestTotal,
    0
  );

  values.orderTotal = values.guestTotal + values.goodsTotal;

  return (
    <Fragment>
      <Wizard.Page>
        <h3>Step 1: Guest Information</h3>
        <br></br>
        <p>
          <strong>Please include yourself in the order!</strong>
        </p>
        <p>
          If you're only purchasing souvenirs and not attending the reunion
          remember to click on "Remove this guest"
        </p>
        <p>
          If your Cart Total comes to more than R 10 000 please split it so that
          any one transaction doesn't exceed R 10 000.
        </p>

        <Container>
          <FieldArray
            name="guests"
            render={arrayHelpers => (
              <Fragment>
                {values.guests.map((attendee, index) => (
                  <Fragment key={index}>
                    <hr />
                    <Row>
                      <Col md={{ size: 8 }}>
                        <h3>Person #{index + 1}</h3>
                      </Col>
                      <Col md={{ size: 4 }} className={"text-right"}>
                        <span onClick={() => arrayHelpers.remove(index)}>
                          <FontAwesomeIcon
                            icon={faMinusSquare}
                            size="1x"
                            color="red"
                          />{" "}
                          Remove this guest
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col>
                            <Attendees index={index} />
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col>
                            <h5>Age:</h5>
                            <Label>
                              <Field
                                name={`guests[${index}].isChild`}
                                type="checkbox"
                                value="true"
                                checked={values.guests[index].isChild}
                              />{" "}
                              Is this Guest Under 13?
                            </Label>
                          </Col>
                        </Row>
                        <hr />
                        <h5>Meals:</h5>
                        <Row>
                          <Col>
                            <Label>
                              <Field
                                name={`guests[${index}].fridaySupperSelected`}
                                type="checkbox"
                                value="true"
                                checked={
                                  values.guests[index].fridaySupperSelected
                                }
                              />{" "}
                              Friday Supper
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>
                              <Field
                                name={`guests[${index}].saturdayLunchSelected`}
                                type="checkbox"
                                value="true"
                                checked={
                                  values.guests[index].saturdayLunchSelected
                                }
                              />{" "}
                              Saturday Lunch
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>
                              <Field
                                name={`guests[${index}].saturdaySupperSelected`}
                                type="checkbox"
                                value="true"
                                checked={
                                  values.guests[index].saturdaySupperSelected
                                }
                              />{" "}
                              Saturday Supper
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>
                              <Field
                                name={`guests[${index}].sundayLunchSelected`}
                                type="checkbox"
                                value="true"
                                checked={
                                  values.guests[index].sundayLunchSelected
                                }
                              />{" "}
                              Sunday Lunch
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Label>
                              <Field
                                name={`guests[${index}].hasAllergies`}
                                type="checkbox"
                                value="true"
                                checked={values.guests[index].hasAllergies}
                              />{" "}
                              Does this guest have food allergies?
                            </Label>
                            <br />

                            {values.guests[index].hasAllergies ? (
                              <Fragment>
                                <div className="form-group">
                                  <label
                                    htmlFor={`guests.[${index}].allergies`}
                                  >
                                    Food Allergies
                                  </label>
                                  <Field
                                    name={`guests.[${index}].allergies`}
                                    component="input"
                                    type="text"
                                    placeholder="allergies..."
                                    className={"form-control"}
                                  />
                                  <ErrorMessage
                                    name={`guests.[${index}].allergies`}
                                    component="div"
                                    className="field-error"
                                  />
                                </div>
                              </Fragment>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12"></Col>
                    </Row>
                  </Fragment>
                ))}
                <Row>
                  <Col className={"text-center"}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => arrayHelpers.push(initialGuestRow)}
                    >
                      <FontAwesomeIcon icon={faPlusSquare} size="lg" /> {"  "}
                      Add another Guest
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            )}
          />
        </Container>
      </Wizard.Page>
    </Fragment>
  );
};

const Attendees = ({ index }) => {
  return (
    <Fragment>
      <div className="form-group">
        <label htmlFor={`guests[${index}].firstName`}>
          First name<font color="red">*</font>
        </label>
        <Field
          name={`guests[${index}].firstName`}
          component="input"
          type="text"
          placeholder="firstName"
          className={"form-control"}
          required={true}
        />
        <ErrorMessage
          name={`guests[${index}].firstName`}
          component="div"
          className="field-error"
        />
      </div>

      <div className="form-group">
        <label htmlFor={`guests[${index}].lastName`}>
          Last name<font color="red">*</font>
        </label>
        <Field
          name={`guests[${index}].lastName`}
          component="input"
          type="text"
          placeholder="lastName"
          className={"form-control"}
          required={true}
        />
        <ErrorMessage
          name={`guests[${index}].lastName`}
          component="div"
          className="field-error"
        />
      </div>

      <div className="form-group">
        <label htmlFor={`guests[${index}].email`}>Email</label>
        <Field
          name={`guests[${index}].email`}
          component="input"
          type="email"
          placeholder="Email"
          className={"form-control"}
        />
      </div>

      <div className="form-group">
        <label htmlFor={`guests[${index}].mobile`}>Cellphone number</label>
        <Field
          name={`guests [${index}].mobile`}
          component="input"
          type="text"
          placeholder="mobile"
          className={"form-control"}
        />
      </div>
    </Fragment>
  );
};

export default Step1;
