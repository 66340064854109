import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import * as ROUTES from "../../../constants/routes";
import { USER_REGISTER } from "../../../constants/apiUrls";
import { SHeader } from "../../StyledComponents";

class SignUpPage extends Component {
  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
              <FontAwesomeIcon icon={faUserPlus} size="5x" />
              <SHeader>Sign Up</SHeader>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 6, offset: 3 }}>
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  passwordConfirmation: "",
                  isSignUpComplete: false
                }}
                onSubmit={(fields, { setStatus, setSubmitting }) => {
                  const { history } = this.props;

                  Axios({
                    method: "post",
                    url: USER_REGISTER,
                    data: {
                      FirstName: fields.firstName,
                      LastName: fields.lastName,
                      Username: fields.email,
                      Password: fields.password
                    }
                  })
                    .then(function(response) {
                      fields.isSignUpComplete = true;
                      setSubmitting(false);
                    })
                    .catch(function(error) {
                      setSubmitting(false);
                      setStatus(error?.response?.data?.message);
                    });
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required("Required"),
                  lastName: Yup.string().required("Required"),
                  email: Yup.string()
                    .email()
                    .required("Required"),
                  password: Yup.string()
                    .required("Required")
                    .min(6),
                  passwordConfirmation: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords must match"
                  )
                })}
              >
                {({ values, errors, status, touched, isSubmitting }) => (
                  <Fragment>
                    {values.isSignUpComplete ? (
                      <Fragment>
                        <div className={"alert alert-success"}>
                          You've signed up!
                          <br /> Check your emails for a confirmation email
                        </div>
                        <Button color="primary" href={ROUTES.HOME}>
                          Go Home
                        </Button>
                      </Fragment>
                    ) : (
                      <Form>
                        <div className="form-group">
                          <label htmlFor="firstName">First Name</label>
                          <Field
                            name="firstName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.firstName && touched.firstName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="lastName">Last Name</label>
                          <Field
                            name="lastName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.lastName && touched.lastName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <Field
                            name="email"
                            type="text"
                            className={
                              "form-control" +
                              (errors.email && touched.email
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="password">Password</label>
                          <Field
                            name="password"
                            type="password"
                            className={
                              "form-control" +
                              (errors.password && touched.password
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="passwordConfirmation">
                            Confirm Password
                          </label>
                          <Field
                            name="passwordConfirmation"
                            type="password"
                            className={
                              "form-control" +
                              (errors.passwordConfirmation &&
                              touched.passwordConfirmation
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="passwordConfirmation"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>

                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Sign Up
                        </Button>
                        {status && (
                          <div className={"alert alert-danger"}>{status}</div>
                        )}
                      </Form>
                    )}
                  </Fragment>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}
export const SignUpLink = () => (
  <p>
    Don't have an account?{" "}
    <Link variant="body2" to={ROUTES.SIGN_UP}>
      Sign Up
    </Link>
  </p>
);

export default withRouter(SignUpPage);
