import React, { Component, Fragment } from "react";

import { Container, Row, Col, Button } from "reactstrap";
import { SHeader } from "../../StyledComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { USER_CONFIMATION } from "../../../constants/apiUrls";
import * as ROUTES from "../../../constants/routes";

import queryString from "query-string";
import Axios from "axios";

class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: null,
      userId: "-",
      token: "-"
    };
  }

  postConfimation(userId, token) {
    Axios.post(USER_CONFIMATION, {
      userId,
      token
    })
      .then(response => {
        this.setState({ isLoading: false });
        //TODO: Handle response
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    const { userId, token } = queryString.parse(this.props.location.search);

    this.setState({
      userId,
      token
    });

    this.postConfimation(userId, token);
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
              <FontAwesomeIcon icon={faUserLock} size="5x" />
              <SHeader>Confirm Account</SHeader>
            </Col>
          </Row>
          <Row>
            <Col colSpan="2"></Col>
          </Row>
          <Row>
            <Col>id: {this.state.userId}</Col>
            <Col>key: {this.state.token}</Col>
          </Row>
        </Container>

        {this.state.isLoading ? (
          <Fragment>Loading...</Fragment>
        ) : this.state.error ? (
          <div className={"alert alert-danger"}>
            There was an error verifying the account
          </div>
        ) : (
          <Fragment>
            <div className={"alert alert-success"}>
              We had success verifying your account
            </div>
            <Button color="primary" href={ROUTES.SIGN_IN}>
              Log in
            </Button>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default ConfirmationPage;
