import React, { Component } from "react";

import { SHeader } from "../../StyledComponents";
import { Table } from "reactstrap";

import styled from "styled-components";

const SCellRight = styled.td`
  text-align: right;
  white-space: pre;
`;
const SHeaderRight = styled.th`
  text-align: right;
  white-space: pre;
`;

const SHeader60 = styled.th`
  width: 60%;
`;

const SValueCell = ({ value }) => {
  return <SCellRight>R {value.toFixed(2)}</SCellRight>;
};

const mealOptions = [
  { mealDescription: "Friday Supper", adultCost: 220, childCost: 110 },
  { mealDescription: "Saturday Lunch", adultCost: 120, childCost: 60 },
  { mealDescription: "Saturday supper", adultCost: 220, childCost: 110 },
  { mealDescription: "Sunday Lunch", adultCost: 120, childCost: 60 }
];

const totalsData = [
  {
    mealDescription: "Friday Supper",
    adults: 23,
    children: 14
  },
  {
    mealDescription: "Saturday Lunch",
    adults: 32,
    children: 4
  }
];

const mealData = [
  {
    mealDescription: "Friday Supper",
    guests: [
      {
        name: "MacLachlan, Kyle",
        mobile: "0829237785",
        isChild: false
      }
    ]
  },
  {
    mealDescription: "Saturday Lunch",
    guests: [
      {
        name: "MacLachlan, Kyle",
        mobile: "0829237785",
        isChild: false
      }
    ]
  }
];

class AdminMealsPage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div>
        <SHeader>Meals Dashboard</SHeader>
        <h4>Meal Options:</h4>
        <p>The options that we have set out for meals</p>
        <Table>
          <thead>
            <tr>
              <SHeader60>Meal</SHeader60>
              <SHeaderRight>Children</SHeaderRight>
              <SHeaderRight>Adults</SHeaderRight>
            </tr>
          </thead>
          <tbody>
            {mealOptions.map(meal => (
              <tr>
                <td>{meal.mealDescription}</td>
                <SValueCell value={meal.childCost} />
                <SValueCell value={meal.adultCost} />
              </tr>
            ))}
          </tbody>
        </Table>
        <h4>TODO: Meal Totals:</h4>
        <p>The total number of each item ordered.</p>
        <Table>
          <thead>
            <tr>
              <SHeader60>Meal</SHeader60>
              <SHeaderRight>Children</SHeaderRight>
              <SHeaderRight>Adults</SHeaderRight>
            </tr>
          </thead>
          <tbody>
            {totalsData.map(meals => (
              <tr>
                <td>{meals.mealDescription}</td>
                <SCellRight>{meals.children}</SCellRight>
                <SCellRight>{meals.adults}</SCellRight>
              </tr>
            ))}
          </tbody>
        </Table>
        <h4>TODO: Guest List:</h4>
        <p>List of people attending each meal:</p>
        <Table>
          {mealData.map(meal => (
            <tbody>
              <tr>
                <th colSpan="2">{meal.mealDescription}</th>
              </tr>
              {meal.guests.map(guest => (
                <tr>
                  <td>
                    {guest.name} {guest.isChild ? "(U/13)" : "(Adult)"}
                  </td>
                  <td style={{ textAlign: "right" }}>{guest.mobile}</td>
                </tr>
              ))}
            </tbody>
          ))}
        </Table>
      </div>
    );
  }
}

export default AdminMealsPage;
