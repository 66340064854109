import React, { Fragment, Component } from "react";
import { Formik, Form } from "formik";
import { Button, Container, Col, Row } from "reactstrap";
import InformationStep from "./infoStep";
import Step1 from "./step1";
import Step2 from "./step2";
import StepCheckout from "./stepCheckout";
import { SHeader } from "../StyledComponents";
import { authenticationService } from "../../services";

import { initialGuestRow } from "./step1";
import Axios from "axios";
import {
  GET_SOUVENIRNS as GET_SOUVENIRS,
  CREATE_ORDER
} from "../../constants/apiUrls";
import authHeader from "../../helpers/auth-header";
import PayFastRedirectPage from "./payFastRedirect";

const INITIAL_VALUES = {
  orderTotal: 0,
  guestTotal: 0,
  goodsTotal: 0,
  guests: [initialGuestRow],
  goods: [],
  isLoadingGoods: true
};

const ORDER_MAX = 10000;

export class Wizard extends React.Component {
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
      souvenirs: []
    };
  }

  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }));

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
  };

  getSouvenirs() {
    Axios.get(GET_SOUVENIRS)
      .then(response => response.data)
      .then(souvenirs => {
        this.setState({
          isLoading: false,
          values: {
            ...this.state.values,
            goods: souvenirs.map(s => ({
              ...s,
              souvenirOrderQuantity: 0
            })),
            isLoadingGoods: false
          }
        });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  componentDidMount() {
    const { values } = this.state;

    this.getSouvenirs();
    authenticationService.currentUser.subscribe(x =>
      this.setState({ currentUser: x })
    );

    if (values.goods.length === 0 && this.state.souvenirs.length > 0)
      this.setState({ values: { ...values, goods: ["hi", "there"] } });
  }

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    return (
      <Fragment>
        {values.isLoadingGoods ? (
          "Loading"
        ) : (
          <Container component="main">
            <Formik
              initialValues={values}
              enableReinitialize={false}
              validate={this.validate}
              onSubmit={this.handleSubmit}
            >
              {({ values, handleSubmit, isSubmitting, handleReset }) => (
                <Form onSubmit={handleSubmit}>
                  {activePage}
                  <div className="buttons">
                    <hr />
                    <Container>
                      <Row>
                        <Col>
                          {page > 0 && (
                            <Button
                              type="button"
                              className="secondary"
                              onClick={this.previous}
                              variant="contained"
                              color="secondary"
                            >
                              « Previous
                            </Button>
                          )}
                        </Col>
                        <Col className="text-right">
                          {!isLastPage && (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                            >
                              Next »
                            </Button>
                          )}
                          {isLastPage && (
                            <Button
                              type="submit"
                              disabled={values.orderTotal > ORDER_MAX}
                              variant="contained"
                              color="success"
                            >
                              Pay Now »
                            </Button>
                          )}
                          {values.orderTotal > ORDER_MAX && (
                            <p>ORDERS MAY NOT EXCEED R10 000</p>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        )}
      </Fragment>
    );
  }
}

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: true,
      orderTotal: 0,
      orderTotal: null
    };
  }

  render() {
    return (
      <Fragment>
        <SHeader>Order Page:</SHeader>
        {this.state.isBusy ? (
          <Wizard
            initialValues={INITIAL_VALUES}
            onSubmit={(values, actions) => {
              this.setState({ isBusy: false });
              this.setState({ orderTotal: values.orderTotal });

              const payload = {
                ...values,
                goods: values.goods.filter(g => g.souvenirTotal > 0)
              };

              Axios.post(CREATE_ORDER, payload, { headers: authHeader() })
                .then(response => {
                  this.setState({ orderId: response.data.orderId });
                  this.setState({ isLoading: false });
                })
                .then(x => {
                  this.refs.myForm.submit();
                })
                .catch(error => this.setState({ error, isLoading: false }));
              actions.setSubmitting(false);
            }}
          >
            <InformationStep />
            <Step1 />
            <Step2 />
            <StepCheckout />
          </Wizard>
        ) : (
          <PayFastRedirectPage
            orderTotal={this.state.orderTotal}
            orderId={this.state.orderId}
            {...this.props}
          ></PayFastRedirectPage>
        )}

        <hr />
      </Fragment>
    );
  }
}

export default OrderPage;
