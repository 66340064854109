import React, { Component, Fragment } from "react";

import { Table, Button } from "reactstrap";
import { SHeader } from "../../StyledComponents";
import { API_BASE_URL } from "../../../constants/apiUrls";

class AdminPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <SHeader>Dashboard</SHeader>
        {loading && <div>Loading ...</div>}
        <UserList users={users} />
        <GuestList users={users} />
      </div>
    );
  }
}

// Registered Users
const UserList = ({ users }) => {
  return (
    <Fragment>
      <pre>API URL: {JSON.stringify(API_BASE_URL)}</pre>
      <h2>User List:</h2>
      <hr />
      <Table aria-label="simple table">
        <thead>
          <tr>
            <td>User ID:</td>
            <td align="right">Email</td>
            <td align="right">UserName</td>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user?.uid}>
              <td align="left">{user?.uid}</td>
              <td align="right">{user?.email}</td>
              <td align="right">{user?.username}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

const TempGuestList = [
  {
    firstName: "Kyle",
    lastName: "MacLachlan",
    email: "kylemac06@gmail.com",
    mobile: "0829237785",
    numberAttending: 5
  }
];

// Users that have been invited
const GuestList = () => {
  return (
    <Fragment>
      <h2>Guest List</h2>
      <Button>Import Users</Button>
      <hr />
      <Table aria-label="simple table">
        <thead>
          <tr>
            <td>First name</td>
            <td>Last name</td>
            <td>Email</td>
            <td>Mobile number</td>
            <td># attending</td>
          </tr>
        </thead>
        <tbody>
          {TempGuestList.map(guest => (
            <tr key={guest?.email}>
              <td>{guest.email}</td>
              <td>{guest.firstName}</td>
              <td>{guest.lastName}</td>
              <td>{guest.mobile}</td>
              <td>{guest.numberAttending}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default AdminPage;
