import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { SHeader } from "../../StyledComponents";
import * as Yup from "yup";
import { Container, Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Axios from "axios";
import { USER_CONFIMATION_RESEND } from "../../../constants/apiUrls";

class ResendConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
      success: false
    };
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className={"text-center"}>
              <FontAwesomeIcon icon={faUserCircle} size="5x" />
              <SHeader>Resend Confimation</SHeader>
            </Col>
          </Row>
          {!this.state.success ? (
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Formik
                  initialValues={{
                    username: ""
                  }}
                  onSubmit={({ username }, { setStatus, setSubmitting }) => {
                    this.setState({ isLoading: true });
                    Axios.post(USER_CONFIMATION_RESEND, {
                      userId: username
                    })
                      .then(response => {
                        this.setState({ isLoading: false, success: true });
                      })
                      .catch(error => {
                        this.setState({
                          error: error.response?.data?.message,
                          isLoading: false
                        });
                      });
                  }}
                  validationSchema={Yup.object().shape({
                    username: Yup.string()
                      .email()
                      .required("Required")
                  })}
                >
                  {({ values, errors, status, touched }) => (
                    <Form>
                      <div className="form-group">
                        <label htmlFor="username">Email</label>
                        <Field
                          name="username"
                          type="text"
                          className={
                            "form-control" +
                            (errors.username && touched.username
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="username"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={this.state.isLoading}
                      >
                        Make it so
                      </Button>

                      <div className="form-group"></div>
                      {this.state.error && (
                        <div className={"alert alert-danger"}>
                          Error! {this.state.error}
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <div className={"alert alert-success"}>
                  Email sent! Please check your inbox
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </Fragment>
    );
  }
}
export const ConfirmationResendLink = () => (
  <p>
    <Link to={ROUTES.CONFIRMATION_RESEND} variant="body2">
      <i>Need a new confimation Link?</i>
    </Link>
  </p>
);
export default ResendConfirmationPage;
