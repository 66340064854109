import React, { Component, Fragment } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { Button, Container, Row, Col } from "reactstrap";
import * as Yup from "yup";
import { SHeader } from "../../../StyledComponents";

class PasswordChangeForm extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <Fragment>
        <Container>
          <Row>
            <Col>
              <hr />
              <SHeader>Change your password</SHeader>
              <hr />
              <Formik
                initialValues={{
                  password: "",
                  passwordConfirmation: ""
                }}
                onSubmit={(fields, { setStatus, setSubmitting }) => {
                  alert(JSON.stringify(fields, null, 2));
                  setStatus();
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required("Required"),
                  passwordConfirmation: Yup.string().oneOf(
                    [Yup.ref("password"), null],
                    "Passwords must match"
                  )
                })}
              >
                {({ values, errors, status, touched, isSubmitting }) => (
                  <div>
                    <Form>
                      <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <Field
                          name="password"
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="passwordConfirmation">
                          Confirm Password
                        </label>
                        <Field
                          name="passwordConfirmation"
                          type="password"
                          className={
                            "form-control" +
                            (errors.passwordConfirmation &&
                            touched.passwordConfirmation
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="passwordConfirmation"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Reset my Password
                      </Button>
                      {status && (
                        <div className={"alert alert-danger"}>{status}</div>
                      )}
                    </Form>
                  </div>
                )}
              </Formik>
              <hr />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default PasswordChangeForm;
