import { authenticationService } from "../services";
import history from "./history";

import JwtDecode from "jwt-decode";

const authHeader = () => {
  // return authorization header with jwt token
  const currentUser = authenticationService.currentUserValue;
  if (currentUser && currentUser.token) {
    if (JwtDecode(currentUser.token).exp < Date.now() / 1000) {
      localStorage.clear();
      alert("Your session has expired, please log in again");
      history.push("/");
    }
    return { Authorization: `Bearer ${currentUser.token}` };
  } else {
    return {};
  }
};

export default authHeader;
