import React, { Fragment, Component, useEffect, useRef } from "react";
import { SHeader } from "../StyledComponents";
import md5 from "md5";
import { authenticationService } from "../../services";
import { ITN_URL } from "../../constants/apiUrls";

class PayFastRedirectPage extends Component {
  componentDidUpdate() {
    const formRedirectHeaderExists = document.getElementById("redirectHeader");

    if (formRedirectHeaderExists) {
      document.getElementById("payfastForm").submit();
    }
  }

  render() {
    const {
      firstName,
      lastName,
      username
    } = authenticationService.currentUserValue;

    const baseURL = process.env.REACT_APP_URL || `https://southeyreunion.co.za`;

    const merchant_id = process.env.REACT_APP_MERCHANT_ID;
    const merchant_key = process.env.REACT_APP_MERCHANT_KEY;

    const m_payment_id = `${this.props.orderId}`;
    const return_url = `${baseURL}/account/orders`;
    const cancel_url = `${baseURL}/account/orders`;
    const notify_url = ITN_URL;
    const name_first = firstName;
    const name_last = lastName;
    const email_address = username;
    const amount = `${this.props.orderTotal}`;
    const item_name = "SoutheyReunion";
    const email_confirmation = "1";
    const confirmation_address = "southeyfamilyreunion@gmail.com";

    const passphrase = process.env.REACT_APP_PASSPHRASE;

    console.log(
      "baseURL:",
      baseURL,
      "\nmerchant_id:",
      merchant_id,
      "\nmerchant_key:",
      merchant_key,
      "\nm_payment_id:",
      m_payment_id,
      "\nreturn_url:",
      return_url,
      "\ncancel_url:",
      cancel_url,
      "\nnotify_url:",
      notify_url,
      "\nname_first:",
      name_first,
      "\nname_last:",
      name_last,
      "\nemail_address:",
      email_address,
      "\namount:",
      amount,
      "\nitem_name:",
      item_name,
      "\nemail_confirmation:",
      email_confirmation,
      "\nconfirmation_address:",
      confirmation_address,
      "\npassphrase:",
      passphrase
    );

    const signatureString =
      `merchant_id=${encodeURIComponent(merchant_id)}&` +
      `merchant_key=${encodeURIComponent(merchant_key)}&` +
      `return_url=${encodeURIComponent(return_url)}&` +
      `cancel_url=${encodeURIComponent(cancel_url)}&` +
      `notify_url=${encodeURIComponent(notify_url)}&` +
      `name_first=${encodeURIComponent(name_first)}&` +
      `name_last=${encodeURIComponent(name_last)}&` +
      `email_address=${encodeURIComponent(email_address)}&` +
      `m_payment_id=${encodeURIComponent(m_payment_id)}&` +
      `amount=${encodeURIComponent(amount)}&` +
      `item_name=${encodeURIComponent(item_name)}&` +
      `email_confirmation=${encodeURIComponent(email_confirmation)}&` +
      `confirmation_address=${encodeURIComponent(confirmation_address)}&` +
      `passphrase=${encodeURIComponent(passphrase)}`;

    const generatedMd5 = md5(signatureString);

    return (
      <Fragment>
        {!this.props.orderId || this.props.orderId < 1 ? (
          <Fragment>
            <SHeader>...</SHeader>
          </Fragment>
        ) : (!this.props.orderTotal || this.props.orderTotal) <= 5 ? (
          <Fragment>
            <SHeader>Payment Below Minimum</SHeader>
          </Fragment>
        ) : (
          <Fragment>
            <SHeader id={"redirectHeader"}>Redirecting to payfast</SHeader>
            <i>OrderId: {this.props.orderId}</i>
            <form
              id={"payfastForm"}
              action={process.env.REACT_APP_PAYFAST_URL}
              method="POST"
            >
              <input type="hidden" name="merchant_id" value={merchant_id} />
              <input type="hidden" name="merchant_key" value={merchant_key} />
              <input type="hidden" name="return_url" value={return_url} />
              <input type="hidden" name="cancel_url" value={cancel_url} />
              <input type="hidden" name="notify_url" value={notify_url} />
              <input type="hidden" name="name_first" value={name_first} />
              <input type="hidden" name="name_last" value={name_last} />
              <input type="hidden" name="email_address" value={email_address} />
              <input type="hidden" name="m_payment_id" value={m_payment_id} />
              <input type="hidden" name="amount" value={amount} />
              <input type="hidden" name="item_name" value={item_name} />
              <input
                type="hidden"
                name="email_confirmation"
                value={email_confirmation}
              />
              <input
                type="hidden"
                name="confirmation_address"
                value={confirmation_address}
              />

              <input type="hidden" name="signature" value={generatedMd5} />
              <p>
                Click the button below if you are not redirected in a few
                seconds...
              </p>

              <button type="submit" form="payfastForm" value="Submit">
                Take me to payfast
              </button>
            </form>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default PayFastRedirectPage;
